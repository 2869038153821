import * as React from 'react';
import {TextField, Button, Icon, Tooltip, IconButton } from '@mui/material';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {ITask, calculateTaskSummary, IRoleSetting, RoleHourType} from '../../../modules';
import {IRootStore} from '../../../RootStore';
import { ItemSummary, TaskActions, TaskDescription } from '..';
import { Construction } from '@mui/icons-material';

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;

    .line-input-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }

    .task, .description, .hours {
        &.hidden {
            INPUT, TEXTAREA {
                color: #ddd;
                text-decoration: line-through;
            }
        }
     }

    .task-hours {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .hours {
        width: 60px;
        text-align: right;
        INPUT {
            text-align: right;

        }
    }

    > BUTTON {
        margin-bottom: 0;
    }

    .description-container {
        flex-basis: 150px;
    }

    .line-summary-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        /* width: 95px; */
        flex-basis: 130px;
    }
`;

export interface ILineItemProps {
    task: ITask;
    onChange: (task: ITask) => void;
    onRemove: () => void;
    onCopy: (task: ITask) => void;
    hasFocus: boolean;
    showAmounts: boolean;
}

export const LineItem: React.FC<ILineItemProps> = (props: ILineItemProps) => {

    const {name, description, hours, overrides = {}} = props.task;

    const {roles, mode, ranges} = useSelector((state: IRootStore) => state.version.version.settings);

    const [isOverride, setOverride] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(true);
    const [_name, setName] = React.useState('');
    const [_description, setDescription] = React.useState('');
    const [_hours, setHours] = React.useState(0);
    const [nameError, setNameError] = React.useState('');
    const [hoursError, setHoursError] = React.useState('');
    const [_overrides, setOverrides] = React.useState<RoleHourType>({...overrides});

    const {onCopy} = props;   

    const primaryRole = roles.find(x => x.isPrimary);
    

    React.useEffect(() => {
        setOverride(props.task.isOverride);
        setIsVisible(props.task.isVisible);

    }, [props.task]);

    React.useEffect(() => {
        if (name !== _name) {
            setName(name);
        }

        if (description !== _description) {
            setDescription(description);
        }

        if (hours !== _hours) {
            setHours(hours);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, hours, description]);


    const onTaskChange = (key: keyof ITask, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = event.currentTarget;

        switch(key) {
            case 'hours':
                const val = Number(value);
                setHours(isNaN(val) ? 0 : val);
                break;
            case 'name':
                setName(value);
                break;
            case 'description':
                setDescription(value);
                break;
            default:
                console.warn(`Can't map Task key: ${key}`);
        }
    };

    const onCopyClick = () => {
        const task = {
            ...props.task,
            name: `Copy of ${_name}`,
            description: _description,
            hours: _hours
        };

        task.summary = calculateTaskSummary(task, roles, ranges)

        onCopy(task);
    };

    const onDescriptionChange = (val: string) => {
        setDescription(val);
    };

    const onFieldBlur = () => {
        const task: ITask = {...props.task};

        setNameError((_name.length < 1) ? 'Required' : '');
        setHoursError((_hours < 0 || hours === 0) ? 'Required' : '');

        if (name !== _name || description !== _description || hours !== _hours) {
            console.log('onFieldBlur');
            task.name = _name;
            task.description = _description;
            task.hours = _hours;
            task.summary = calculateTaskSummary(task, roles, ranges);
            props.onChange(task);
        }
    };

    const onFocus = (event: React.FocusEvent) => {
        (event.currentTarget as HTMLInputElement).select();
    };

    // const callbackRef = React.useCallback((inputElement: HTMLElement) => {
    //     if (inputElement && props.hasFocus) {
    //         inputElement.focus();
    //     }
    // }, [props.hasFocus]);

    const toggleOverride = () => {
        const _isOverride = !isOverride;

        const task = {...props.task};
        task.isOverride = _isOverride;
        
        if (_isOverride) {
            // move the task hours into the overrides
            const hours = task.hours;
            const summary = calculateTaskSummary({...task, isOverride: false}, roles, ranges);

            task.overrides = Object.entries(summary)
                .filter(([name, ]) => name !== 'Total')
                .reduce((acc, [name, taskSummary]) => {
                    return {...acc, [name]: taskSummary.hours}
                }, {})

            task.overrides[primaryRole!.role] = hours;

            task.hours = 0;
        } else {

            // add up the total hours from the override and set it as the task hours
            // task.hours = Object.entries(task.overrides || {})
            //     .filter(([role, hours]) => role === primaryRole!.role)
            //     .map(([, hours]) => hours || 0)
            //     .reduce((agg, curr) => agg + curr, 0);
            task.hours = task.overrides![primaryRole!.role] || 0;

            task.overrides = {};
        }

        task.summary = calculateTaskSummary(task, roles, ranges);

        props.onChange(task);

        setOverride(_isOverride);
    };

    const toggleVisibility = () => {
        const task = {...props.task};
        task.isVisible = !isVisible;

        setIsVisible(!isVisible);
        props.onChange(task);
    };

    const onOverrideChange = (role: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        const _overrides = {...overrides};
        const val = Number(event.currentTarget.value);
        _overrides[role] = isNaN(val) ? 0 : val;
        setOverrides(_overrides);
    };

    const onOverrideBlur = (role: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const task = {...props.task};

        if (!task.overrides) {
            task.overrides = {};
        }

        const val = Number(event.currentTarget.value);
        const value = isNaN(val) ? 0 : val;
        
        if (value !== overrides[role]) {
            // only fire the event if the value changed
            task.overrides = _overrides;
    
            task.summary = calculateTaskSummary(task, roles, ranges);
    
            props.onChange(task);
        }

    };


    const overrideEls = roles
        .filter((x: IRoleSetting) => x.enabled)
        .sort((a, b) => {
            if (a.isPrimary) return -1;
            else if (!a.isPrimary) return 1;
            else return 0;
        })
        .map((x: IRoleSetting, index: number) => {
            const value = (_overrides[x.role]) ? _overrides[x.role] : 0;
            return (
                <TextField
                    key={index}
                    variant="standard"
                    style={{marginLeft: '2px'}}
                    size="small"
                    inputProps={{'data-testid': `${x.role}-override-hours`, min: 0, max: 99999, maxLength: 5}}
                    className={`hours ${isVisible ? '' : 'hidden'}`}
                    // type="number"
                    label={`${x.role} hrs`}
                    value={value}
                    draggable={true}
                    onDragStart={(event) => event.preventDefault()}
                    onChange={(event) => onOverrideChange(x.role, event)}
                    onBlur={(e) => onOverrideBlur(x.role, e)}
                    onFocus={onFocus}
                />
            )
        });

    return (
        <Container className={(isOverride) ? 'override' : ''} onBlur={onFieldBlur}>
            <div className="line-input-container">
                <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end'}}>
                    <div style={{flexGrow: 1}}>
                        <TextField
                            className={`task ${isVisible ? '' : 'hidden'}`}
                            variant="standard"
                            size="small"
                            InputLabelProps={{ shrink: false }}
                            inputProps={{'data-testid': `name`}}
                            // label="Task"
                            placeholder="Task"
                            value={_name}
                            // inputRef={callbackRef}
                            onChange={(e) => onTaskChange('name', e)}
                            // onBlur={onFieldBlur}
                            onFocus={onFocus}
                            draggable={true}
                            onDragStart={(event) => event.preventDefault()}
                            fullWidth={true}
                            error={nameError !== ''}
                            // helperText={nameError}
                            multiline={true}
                            sx={{
                                "& .MuiInputBase-input": {
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }
                            }}
                            style={{minWidth: '200px'}}
                        />
                    </div>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end'}}>
                        { 
                            !isOverride && 
                            <TextField
                                className={`hours ${isVisible ? '' : 'hidden'}`}
                                variant="standard"
                                size="small"
                                draggable={true}
                                onDragStart={(event) => event.preventDefault()}
                                style={{marginLeft: '5px'}}
                                inputProps={{'data-testid': `hours`, min: 0, max: 99999, maxLength: 5}}
                                // type="number"
                                label={`${primaryRole?.role} Hours`}
                                value={_hours}
                                onChange={(e) => onTaskChange('hours', e)}
                                // onBlur={onFieldBlur}
                                onFocus={onFocus}
                                error={hoursError !== ''}
                                // helperText={hoursError}
                            />
                        }
                        {
                            isOverride && overrideEls
                        }
                        {
                            (mode === 'COMPUTED') &&
                            <Tooltip title="Override Hours" placement="bottom">
                                <IconButton onClick={toggleOverride} data-testid="override" tabIndex={-1}>
                                    <Construction fontSize="inherit" color={isOverride ? 'primary' : 'disabled'} />
                                </IconButton>
                                {/* <Button onClick={toggleOverride} data-testid="override" tabIndex={-1}>
                                    <Icon color={isOverride ? 'primary' : 'disabled'}>construction</Icon>
                                </Button> */}
                            </Tooltip>
                        }
                    </div>
                </div>
                <div className="description-container">
                    <TaskDescription value={_description} onChange={onDescriptionChange} isVisible={props.task.isVisible} />
                </div>
                <div className="line-summary-container">
                    
                    <ItemSummary summary={props.task.summary} showAmounts={props.showAmounts} strike={!props.task.isVisible} />

                    <TaskActions 
                        isVisible={props.task.isVisible}
                        onCopyClick={onCopyClick}
                        onVisibilityClick={toggleVisibility}
                        onDeleteClick={props.onRemove}
                    />
                </div>
            </div>
        </Container>
    );
};
