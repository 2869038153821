
import {IEstimate, IRoleSetting, ISection, ISettings, ITask} from './Models';
import {RequestBuilder} from '../';

const HOST = `${process.env.REACT_APP_BACKEND_HOST}/estimates`;

export async function listEstimates(filters: {name: string, value: string}[], page: number, size: number) {

    const url = `${HOST}/search`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .addParams(filters)
        .addParam('page', page.toString())
        .addParam('size', size.toString())
        .build();

};

export async function getEstimate(id: string) {

    const url = `${HOST}/${id}`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();  
};

export interface ICreateEsimateRequest {
    client: string;
    title: string;
    projectCode: string;
    mode: string;
    primaryRole?: IRoleSetting;
    additionalRoles?: IRoleSetting[];
}
export async function createEstimate(request: ICreateEsimateRequest) {

    const url = `${HOST}`;

    return await new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody<ICreateEsimateRequest>(request)
        .build();
};

export async function updateEstimate(estimate: IEstimate) {

    const url = `${HOST}/${estimate.id}`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody<IEstimate>(estimate)
        .build();
};

export async function deleteEstimate(id: string) {
    
    const url = `${HOST}/${id}`;

    return await new RequestBuilder(url)
        .setMethod('DELETE')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function getEstimateHistory(estimateId: string) {
    const url = `${HOST}/${estimateId}/history`;

    return new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function getEstimateVersions(estimateId: string) {
    const url = `${HOST}/${estimateId}/versions`;

    return new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function getEstimateVersionById(estimateId: string, versionId: string) {
    const url = `${HOST}/${estimateId}/versions/${versionId}`;

    return new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function addTask(estimateId: string, versionId: string, sectionId: string, task: ITask) {
    const url = `${HOST}/${estimateId}/${versionId}/sections/${sectionId}/tasks`;

    return new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody<{task: ITask}>({task})
        .build();
}

export async function updateTask(estimateId: string, versionId: string, sectionId: string, task: ITask) {
    const url = `${HOST}/${estimateId}/${versionId}/sections/${sectionId}/tasks/${task.id}`;

    return new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody<{task: ITask}>({task})
        .build();
}

export async function deleteTask(estimateId: string, versionId: string, sectionId: string, taskId: string) {
    const url = `${HOST}/${estimateId}/${versionId}/sections/${sectionId}/tasks/${taskId}`;

    return new RequestBuilder(url)
        .setMethod('DELETE')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function addSection(estimateId: string, versionId: string, section: ISection) {
    const url = `${HOST}/${estimateId}/${versionId}/sections`;

    return new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody<{section: ISection}>({section})
        .build();
}

export async function updateSection(estimateId: string, versionId: string, section: ISection) {
    const url = `${HOST}/${estimateId}/${versionId}/sections/${section.id}`;

    return new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody<{section: ISection}>({section})
        .build();
}

export async function deleteSection(estimateId: string, versionId: string, sectionId: string) {
    const url = `${HOST}/${estimateId}/${versionId}/sections/${sectionId}`;

    return new RequestBuilder(url)
        .setMethod('DELETE')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function getFilters() {
    const url = `${HOST}/filters`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function setStatus(id: string, status: string) {
    const url = `${HOST}/${id}/status`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody({status})
        .build();
}

export async function getComments(id: string) {
    const url = `${HOST}/${id}/comments`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function addComment(id: string, comment: string) {
    const url = `${HOST}/${id}/comments`;

    const body = {
        comment
    };

    return await new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody(body)
        .build();
}

export async function getAssumptions(id: string) {
    const url = `${HOST}/${id}/assumptions`;

    return await new RequestBuilder(url)
        .setMethod('GET')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function createAssumption(id: string, assumption: string) {
    const url = `${HOST}/${id}/assumptions`;

    const body = {
        assumption
    };

    return await new RequestBuilder(url)
        .setMethod('POST')
        .setBody(body)
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function updateAssumption(id: string, asssumptionId: string, assumption: string) {
    const url = `${HOST}/${id}/assumptions/${asssumptionId}`;

    const body = {
        assumption
    };

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .setBody(body)
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function deleteAssumption(id: string, asssumptionId: string) {
    const url = `${HOST}/${id}/assumptions/${asssumptionId}`;

    return await new RequestBuilder(url)
        .setMethod('DELETE')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function copyEstimate(id: string) {
    const url = `${HOST}/${id}/copy`;

    return await new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function revision(id: string) {
    const url = `${HOST}/${id}/revision`;

    return await new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function updateVersion(id: string, versionId: string, description: string) {
    const url = `${HOST}/${id}/versions/${versionId}`;

    return await new RequestBuilder(url)
        .setMethod('PATCH')
        .addAuthorizationHeader()
        .setBody({description})
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function setPrimaryVersion(id: string, versionId: string) {
    const url = `${HOST}/${id}/revision/${versionId}`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function resetSettings(id: string, versionId: string) {
    const url = `${HOST}/${id}/versions/${versionId}/reset`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .build();
}

export async function changePrimaryRole(id: string, versionId: string, primaryRole: string) {
    const url = `${HOST}/${id}/versions/${versionId}/changeRole`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody({primaryRole})
        .build();
}

export async function updateSettings(id: string, versionId: string, settings: ISettings) {
    const url = `${HOST}/${id}/versions/${versionId}/settings`;

    return await new RequestBuilder(url)
        .setMethod('PUT')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody({settings})
        .build();
}

export async function downloadCsv(id: string, versionId: string, summaryType: 'low'|'mid'|'high') {
    const url = `${process.env.REACT_APP_BACKEND_HOST}/export/csv`;

    return new RequestBuilder(url)
        .setMethod('POST')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody({id, versionId, summaryType})
        .build();
}

export async function addDecision(id: string, decision: 'APPROVED'|'CHANGES_REQUESTED', comment?: string) {
    const url = `${HOST}/${id}/decision`;

    return await new RequestBuilder(url)
        .setMethod('PATCH')
        .addAuthorizationHeader()
        .addHeader('Content-Type', 'application/json')
        .setBody({decision, comment})
        .build();
}