import * as React from 'react';
import {useSelector} from 'react-redux';
import {Button, Icon, TextField, Menu, MenuItem, ListItemIcon, IconButton, Paper} from '@mui/material';
import styled from 'styled-components';

import {LineItem, Draggable} from '../../ui';
import { ITask, calculateSectionSummary, ISection } from '../../../modules';
import {ItemSummary} from './ItemSummary';
import { IRootStore } from '../../../RootStore';
import { ArrowForwardIos, MoreVert } from '@mui/icons-material';

const Container = styled.div`

    .section {
        background-color: #f2fcff; // eee
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px 10px 5px;
        position: sticky;
        top: 60px;
        z-index: 2;
        border: 1px solid #006E90;

        > DIV {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -1px
        }

        .show-tasks {
            transform: rotate(90deg);
        }

        .hide-tasks {
            transform: rotate(0deg);
        }
    }

    .items {
        padding-bottom: 10px;

        .summary {
            padding: 30px 20px 30px 5px;
            font-weight: bold;
        }
    }

    .item {
        background-color: #fff;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0 2px 0;
        margin-right: 10px;
        width: 100%;

        &:nth-child(even) {
            /* background-color: #eee; */
            background-color: rgba(238, 238, 238, .5);
        }

        /* &:hover {
            background-color: rgba(238, 238, 238, .1);
        } */

        .line-item {
            width: 100%;
            max-width: 99%;
        }

        .line-summary {
            min-width: 100px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .btn-container {
        padding: 5px;
        margin-left: 10px;
        min-width: 130px;
    }
`;

export interface ISectionProps {
    section: ISection;
    showDetails: boolean;
    hasFocus: boolean;
    onChange: (name: string, tasks: ITask[]) => void;
    onCopy: (id: string | number) => void;
    onDelete: () => void;
}

export const Section: React.FC<ISectionProps> = (props: ISectionProps) => {

    const {section} = props;
    const {tasks} = section;

    const [name, setName] = React.useState(props.section.name);
    const [items, setItems] = React.useState<ITask[]>([...props.section.tasks]);
    const [indexFocus, setIndexFocus] = React.useState(-1);
    const [showTasks, setShowTasks] = React.useState(true);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const {settings: {mode, ranges, roles} } = useSelector((state: IRootStore) => state.version.version);

    React.useEffect(() => {
        setItems(tasks);
        setName(props.section.name);
    }, [tasks, props.section.name]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const moveItem = React.useCallback((dragIndex: number, hoverIndex: number) => {
            console.log(`dragIndex=${dragIndex}, hoverIndex=${hoverIndex}`)
            const _items = [...items];
            
            // reorder the array
            const dragItem = _items[dragIndex];
            _items.splice(dragIndex, 1);
            _items.splice(hoverIndex, 0, dragItem);

            // set items
            // const newItems = [..._items];
            setItems(_items);

            // notify of change (updates the store)
            props.onChange(name, _items);
        },
        [items, name, props]
    );    

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.currentTarget.value;
        setName(newName);
    };

    const onNameBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (name !== section.name) {
            props.onChange(name, items);
        }
    };

    const addLineClick = () => {
        const item = {
            id: new Date().getTime(),
            name: '', 
            description: '', 
            hours: 0, 
            isOverride: (mode === 'MANUAL'),
            isVisible: true,
            summary: {},
            overrides: {} 
        };
        const _items = [...items, item];
        setItems(_items);
        setIndexFocus(_items.length - 1);
        props.onChange(name, _items);
    };

    const onCopyTask = (task: ITask) => {
        const item = {
             ...task,
            id: new Date().getTime(),
        };
        const _items = [...items, item];
        setItems(_items);
        setIndexFocus(_items.length - 1);
        props.onChange(name, _items);
    };

    const onRemoveLine = (id: number) => {
        const newItems = items.filter(x => x.id !== id);
        setItems(newItems);
        props.onChange(name, newItems);
    };

    const onCopyClick = () => {
        props.onCopy(props.section.id);
        handleClose();
    };

    const onItemChange = (task: ITask, index: number) => {
        const _items = [...items];
        _items[index] = task;

        setItems(_items);
        props.onChange(name, _items);
    };

    const onFocus = (event: React.FocusEvent) => {
        (event.currentTarget as HTMLInputElement).select();
    };

    const callbackRef = React.useCallback((inputElement: HTMLElement) => {
        if (inputElement && props.hasFocus) {
            inputElement.focus();
        }
    }, [props.hasFocus]);

    const sectionTotalHours = calculateSectionSummary(section, roles, ranges);

    return (
        <Container>
            <Paper elevation={1} className="section">
                <div>
                    <IconButton color="primary" onClick={() => setShowTasks(!showTasks)}>
                        <ArrowForwardIos className={showTasks ? 'show-tasks' : 'hide-tasks'} />
                    </IconButton>

                    <TextField
                        label="Section Name"
                        value={name}
                        style={{minWidth: '400px', width: '100%', backgroundColor: 'white'}}
                        inputProps={{width: '100%'}}
                        variant="outlined"
                        inputRef={callbackRef}
                        onChange={onNameChange}
                        onBlur={onNameBlur}
                        onFocus={onFocus}
                    />

                {
                    showTasks &&
                    <div className="btn-container">
                        <Button color="primary" variant="text" onClick={addLineClick}>
                            <Icon>add</Icon>
                            <span>&nbsp;Task</span>
                        </Button>
                    </div>
                }
                </div>
                <div className="totals">
                    <ItemSummary summary={sectionTotalHours} showAmounts={props.showDetails} strike={false} />

                    <IconButton onClick={handleClick} size="small">
                        <MoreVert fontSize="inherit" />
                    </IconButton>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={onCopyClick}>
                            <ListItemIcon>
                                <Icon color="action">file_copy</Icon>
                            </ListItemIcon>
                            <span>Copy</span>
                        </MenuItem>
                        <MenuItem onClick={() => { props.onDelete(); handleClose(); } }>
                            <ListItemIcon>
                                <Icon color="secondary">delete_forever</Icon>
                            </ListItemIcon>
                            <span>Delete</span>
                        </MenuItem>
                    </Menu>
                </div>
            </Paper>

            <div className="items">
                {
                    showTasks &&
                    items.map((x: ITask, index: number) => {
                        return (
                            <div className="item" key={index}>
                            <Draggable key={index} id={x.id} index={index} onMove={moveItem}>
                                    <div className="line-item">
                                        <LineItem
                                            task={x}
                                            onChange={(task: ITask) => onItemChange(task, index)}
                                            onRemove={() => onRemoveLine(x.id)}
                                            onCopy={onCopyTask}
                                            hasFocus={indexFocus === index}
                                            showAmounts={props.showDetails}
                                        />
                                    </div>
                            </Draggable>
                            </div>
                        );
                    })
                }
                {
                    !showTasks &&
                    <div className="summary">
                        <Button variant="text" color="primary" onClick={() => setShowTasks(true)}>
                            <span>Show {items.length} tasks</span>
                        </Button>
                    </div>
                }
            </div>
        </Container>
    );
};
