import { Button, Icon, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IRootStore } from '../../../RootStore';
import { NumberFormat } from '../NumberFormat';
import { useDispatch } from 'react-redux';
import { estimationSetPrimaryVersionAction, versionListAction, versionNewAction } from '../../../modules';

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

const Container = styled.div`

    TBODY {
        TR {
            &:hover {
                background-color: #eee;
                cursor: pointer;
            }
        }
    }

    .version-number {
        display: flex;
        align-items: end;
        align-content: center;
    }

    .button-container {
        margin-top: 10px;
    }

    .description-container {
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

`;

export interface IVersionList {
    onSelect: (id: string|null) => void;
}

export const VersionList: React.FC<IVersionList> = (props: IVersionList) => {
    const {onSelect} = props;

    const dispatch = useDispatch();
    
    const [selectedVersion, setSelectedVersion] = React.useState<string | null>(null);
    
    const {versions = [], version, isBusy} = useSelector((state: IRootStore) => state.version);
    const {estimate: {id, version: versionId, status}} = useSelector((state: IRootStore) => state.estimation);

    const canEditEstimate = status !== 'PUBLISHED' && status !== 'CLOSED' && status !== 'PENDING';

    React.useEffect(() => {
        dispatch(versionListAction({estimateId: id}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRowClick = (id: string) => {
        setSelectedVersion(id);

        onSelect(id === version.id ? null : id);
    };

    const onFavoriteClick = (event: React.MouseEvent, _versionId: string) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(estimationSetPrimaryVersionAction({estimateId: id, versionId: _versionId}));
    };

    const onNewVersionClick = () => {
        dispatch(versionNewAction({estimateId: id}));
    };

    const versionEls = versions.map((v, index) => (
        <TableRow style={{backgroundColor: v.id === selectedVersion ? '#ddd' : 'inherit'}} onClick={() => onRowClick(v.id)} key={index}>
            <TableCell>
                <div className="version-number">
                    <span>{v.version}</span>
                    {
                        v.id === version.id &&
                        <Tooltip title="Currently Viewing" sx={{ml: 1}}>
                            <Icon color="disabled" fontSize="small">visibility</Icon>
                        </Tooltip>
                    }
                </div>
            </TableCell>
            <TableCell>
                <Tooltip title={v.description}>
                    <div className="description-container">
                        {v.description}
                    </div>
                </Tooltip>
            </TableCell>
            <TableCell align="right">
                <NumberFormat value={v.summary.hours} isCurrency={false} />
            </TableCell>
            <TableCell align="right">
                <NumberFormat value={v.summary.amount} isCurrency={true} />
            </TableCell>
            <TableCell>
                {new Intl.DateTimeFormat('en-US', options).format(new Date(v.createDate))}
            </TableCell>
            <TableCell>
                {new Intl.DateTimeFormat('en-US', options).format(new Date(v.updateDate))}
            </TableCell>
            <TableCell align="center">
                {
                    canEditEstimate && v.id !== versionId &&
                    <Tooltip title="Set as primary version">
                        <Button onClick={(e) => onFavoriteClick(e, v.id)}>
                            <Icon>star_outline</Icon>
                        </Button>
                    </Tooltip>
                }
                {
                    v.id === versionId &&
                    <Tooltip title="Primary version">
                        <Icon color="primary" fontSize="small">star</Icon>
                    </Tooltip>
                }
            </TableCell>
        </TableRow>
    ));

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Version</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Hours</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { versionEls }
                </TableBody>
            </Table>
            <div className="button-container">
                {
                    canEditEstimate &&
                    <Button variant="contained" disabled={isBusy} onClick={onNewVersionClick}>
                        <Icon>add</Icon>
                        <span>New Version</span>
                    </Button>
                }
            </div>
        </Container>
    );
};
