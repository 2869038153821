import * as React from 'react';
import styled from 'styled-components';
import {Backdrop, CircularProgress, Paper, Tabs, Tab, Badge} from '@mui/material';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';

import {Wbs, WbsReadOnly, CommentsList, HistoryList, AssumptionsEditor, EstimationHeader, ProjectPlan, GeneralInfo, ApprovalList} from '../ui';
import {IRootStore} from '../../RootStore';
import {assumptionsListAction, estimationGetAction, estimationListCommentsAction, summaryEstimateSetAction, versionListAction, versionSetVersionAction} from '../../modules';
import { Params } from 'react-router-dom';
import {usePrompt} from '../../hooks';
import { SettingsModal } from '../modals';
import { SalesSummaryRateCard } from '../ui/estimation/SalesSummaryRateCard';
import { SalesSummaryTaskSummary } from '../ui/estimation/SalesSummaryTaskSummary';
import { Business, Chat, FormatListBulleted, Sell, Summarize, TrendingDown, Verified, History } from '@mui/icons-material';

const Container = styled.div`
  display: flex;

    .content-container {
      margin: 10px 0;
      background-color: white;
      padding: 15px;
    }

    .main-content-container {
      width: 100%;
    }

    .backdrop {
      color: #fff;
      z-index: 1000;
    }

    .title {
      margin-bottom: 15px;
    }

    .fab-container {
      position: fixed;
      bottom: 100px;
      right: 100px;
      z-index: 10;
    }

`;


export interface IEstimatePageProps {

}

export const EstimatePage: React.FC<IEstimatePageProps> = (props: IEstimatePageProps) => {
  const params: Readonly<Params<string>> = useParams();
  const dispatch = useDispatch();
  const {estimate, isBusy, hasChanged, comments, history} = useSelector((state: IRootStore) => state.estimation);
  const {version} = useSelector((state: IRootStore) => state.version);
  const {assumptions} = useSelector((state: IRootStore) => state.assumptions);

  const [view, setView] = React.useState('tasks');
  const [isInfoOpen, setIsInfoOpen] = React.useState(false);

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave this page?',
    hasChanged
  )

  const {id} = params;

  React.useEffect(() => {
    if (id) {
      dispatch(estimationGetAction({id}));
      dispatch(summaryEstimateSetAction({version}));
      dispatch(versionListAction({estimateId: id}));
      dispatch(assumptionsListAction({estimateId: id}));
      dispatch(estimationListCommentsAction({id}));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (estimate && estimate.version) {
      dispatch(versionSetVersionAction({versionId: estimate.version}));
    }

  }, [estimate.version]);


  const readOnlyMode = estimate.status === 'PUBLISHED' || estimate.status === 'CLOSED' || estimate.status === 'PENDING';

  const changeView = (action: string) => {

    switch(action){
      case 'info':
        setIsInfoOpen(true);
        break;
      default:
        setView(action);
    }
  };

  const counts = {
    'Tasks & Hours': version.sections.map(s => s.tasks).flat().length || 0,
    'Comments': comments.length || 0,
    'History': history.length || 0,
    'Assumptions': assumptions.length || 0,
    'Approvals': estimate.approvals.length || 0
  };

  const getTabIcon = (count: number, icon: React.ReactElement) => {
    if (count) {
      return <Badge badgeContent={count} color="secondary">{icon}</Badge>
    }
    
    return icon;
  };

  return (
    <Container>

      <div>
        <Backdrop className="backdrop" open={isBusy}>
          <CircularProgress />
        </Backdrop>
      </div>

      <div className="main-content-container">

        <Paper elevation={3}>
          <EstimationHeader />
          <div style={{borderTop: '1px solid #eee'}}>
            <Tabs value={view} centered={true} variant="fullWidth" onChange={(e, val) => changeView(val)}>
              <Tab label="Tasks & Hours" value="tasks" icon={<FormatListBulleted />} />
              <Tab label="Approvals" value="approvals" icon={getTabIcon(counts['Approvals'], <Verified />)} />
              <Tab label="Assumptions" value="assumptions" icon={getTabIcon(counts['Assumptions'], <Business />)} />
              <Tab label="Comments" value="comments" icon={getTabIcon(counts['Comments'], <Chat />)} />
              <Tab label="History" value="history" icon={getTabIcon(counts['History'], <History />)} />
              <Tab label="Project Plan" value="projectPlan" icon={<TrendingDown />} />
              <Tab label="Rate Card" value="rateCard" icon={<Sell />} />
              <Tab label="Task Summary" value="taskSummary" icon={<Summarize />} />
            </Tabs>
          </div>
        </Paper>

        <Paper elevation={2}>
          <div className="content-container">
            {
              view === 'tasks' && readOnlyMode &&
              <div>
                <WbsReadOnly />
              </div>
            }
            {
              view === 'tasks' && !readOnlyMode &&
              <div>
                <Wbs />
              </div>
            }
            {
              view === 'approvals' &&
              <div>
                <ApprovalList />
              </div>
            }
            {
              view === 'history' &&
              <div>
                <HistoryList />
              </div>
            }
            {
              view === 'assumptions' &&
              <div>
                <AssumptionsEditor />
              </div>
            }
            {
              view === 'comments' &&
              <div>
                <CommentsList />
              </div>
            }
            {
              view === 'projectPlan' &&
              <div>
                <ProjectPlan />
              </div>
            }
            {
              view === 'rateCard' &&
              <div>
                <SalesSummaryRateCard />
              </div>
            }
            {
              view === 'taskSummary' &&
              <div>
                <SalesSummaryTaskSummary />
              </div>
            }
            <SettingsModal open={isInfoOpen} title="Project Information" onClose={() => setIsInfoOpen(false)}>
              <GeneralInfo />
            </SettingsModal>
          </div>
        </Paper>

        <div style={{minHeight: '400px'}}></div>
      </div>

    </Container>
  );
};
