import * as React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

// SO MANY HACKS! React router v6 does not support route blocking.
// This is a custom hook to shim in a route block
// AKA prompt them before navigating away from the page

export function useBlocker(blocker: (arg: any) => void, when = true) {
  const { navigator } = React.useContext(NavigationContext) as any;
  React.useEffect(() => {
    if (!when) return;
    const unblock = navigator.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: string, when = true) {
  const blocker = React.useCallback(
    (tx: any) => {
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );
  useBlocker(blocker, when);
}
