import * as React from 'react';
import {Button, Icon, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {Section} from './Section';
import {IRootStore} from '../../../RootStore';
import {ISection, ITask, versionSectionAddAction, versionSectionDeleteAction, versionSectionUpdateAction, versionUpdateAction} from '../../../modules';
import { UpdatedTimestamp } from '../UpdatedTimestamp';


const Container = styled.div`
    width: 100%;

    .input-container {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        .description-container {
            display: flex;
            align-items: center;
        }
    }

    > BUTTON {
        margin-bottom: 15px;
    }

    .section-totals {
        background-color: #6cbbe0;
        margin-top: 30px;
        padding: 10px;    
    }

    .timestamp-container {
        padding-right: 15px;
    }
`;

export interface IWbsProps {

}

export const Wbs: React.FC<IWbsProps> = (props: IWbsProps) => {
    const [indexFocus, setIndexFocus] = React.useState(-1);

    const dispatch = useDispatch();

    const [_description, setDescription] = React.useState('');

    const {version} = useSelector((state: IRootStore) => state.version);
    const {sections = [], updateDate, description} = version;

    const {settings: {showAmounts}} = useSelector((state: IRootStore) => state.estimation);

    React.useEffect(() => {
        setDescription(description);
    }, [description]);
    
    const addSectionClick = () => {
        const section: ISection = {id: new Date().getTime(), name: 'New Section', tasks: []};
        dispatch(versionSectionAddAction({section}));
        setIndexFocus(sections.length);
    };

    const deleteSectionClick = (id: number | string) => {
        dispatch(versionSectionDeleteAction({id}));
    }

    const onSectionChange = (_section: ISection, name: string, tasks: ITask[]) => {
        const section = {..._section, name, tasks};
        dispatch(versionSectionUpdateAction({section}));
    };

    const onSectionCopy = (sectionId: string | number) => {
        const [section] = sections.filter(x => x.id === sectionId);
        
        const sectionCopy = {...section, id: new Date().getTime(), name: `${section.name} Copy`};
        dispatch(versionSectionAddAction({section: sectionCopy}));
        setIndexFocus(sections.length);
    };

    const sectionEls = sections.map((x: ISection, index: number) => (
        <div key={index}>
            <Section
                section={x}
                showDetails={showAmounts}
                hasFocus={indexFocus === index}
                onChange={(name, tasks) => onSectionChange(x, name, tasks)}
                onCopy={onSectionCopy}
                onDelete={() => deleteSectionClick(x.id)}
            />
        </div>
    ));

    return (
        <Container>
            <div className="input-container">
                <div className="description-container">
                    <div>
                        <TextField
                            value={_description}
                            label="Description"
                            variant="standard"
                            onChange={(e) => setDescription(e.target.value)}
                            onBlur={(e) => dispatch(versionUpdateAction({version: {...version, description: e.target.value}}))}
                            style={{ width: '45vw' }}
                            inputProps={{ maxLength: 300 }}
                        />
                    </div>
                    <div style={{marginLeft: '10px'}}>
                        <Button color="primary" variant="text" onClick={addSectionClick}>
                            <Icon>add</Icon>
                            <span>&nbsp;Section</span>
                        </Button>
                    </div>
                </div>

                <div className="timestamp-container">
                    <UpdatedTimestamp date={updateDate} />
                </div>
            </div>
            
            {
                sectionEls
            }
        </Container>
    );
};
