import * as React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {Button, Backdrop, CircularProgress, Icon, Paper} from '@mui/material';

import {IRootStore} from '../../RootStore';
import {EstimateList, EstimateFilter} from '../ui';
import {NewEstimateModal} from '../modals';
import {listSearchAction, estimationSetAction, IEstimate, generateBlankEstimate} from '../../modules';

const Container = styled.div`

    .filter-container {
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        padding: 10px 15px 20px 15px;
    }

    .title-container {
        display: flex;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: Lato;
        align-items: center;
    }
`;

export interface IListPageProps {

}

export const ListPage: React.FC<IListPageProps> = (props: IListPageProps) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {estimates, page, size, pagination, filters, copyActionSuccess, revisionActionSuccess, isBusy: isSearching} = useSelector((state: IRootStore) => state.estimateList);
    const {isBusy, estimate} = useSelector((state: IRootStore) => state.estimation);

    const [isNewEstimateOpen, setIsNewEstimateOpen] = React.useState(false);
    const [redirectOnSave, setRedirectOnSave] = React.useState(false);

    const isBackdropOpen = isSearching || isBusy;


    React.useEffect(() => {
        dispatch(listSearchAction({filters, page, size}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (revisionActionSuccess && revisionActionSuccess.id) {
            dispatch(estimationSetAction({estimate: revisionActionSuccess as IEstimate}));
            navigate(`/estimate/${revisionActionSuccess!.id}`);
        }
    }, [revisionActionSuccess, dispatch, navigate]);

    React.useEffect(() => {
        if (copyActionSuccess && copyActionSuccess.id) {
            dispatch(estimationSetAction({estimate: copyActionSuccess as IEstimate}))
            navigate(`/estimate/${copyActionSuccess!.id}`);
        }
    }, [copyActionSuccess, dispatch, navigate]);

    const onEstimateClick = (id: string) => {
        const estimate = estimates.find(x => x.id === id);
        
        if (estimate) {
            // dispatch(estimationSetAction({estimate}));
            navigate(`/estimate/${id}`);
        } else {
            console.warn('Estimate not found? /shrug');
        }
    };

    const onPaginationChange = (_page: number, _size: number) => {
        dispatch(listSearchAction({filters, page: _page, size: _size}));
    };

    const onNewEstimateClick = () => {
        setRedirectOnSave(false);
        dispatch(estimationSetAction({estimate: generateBlankEstimate()}));
        setIsNewEstimateOpen(true);
    };

    const onSave = () => {
        setIsNewEstimateOpen(false);
        setRedirectOnSave(true);
    };

    React.useEffect(() => {
        // Wait for the new estimate to be created and returned before we change the route.
        if (redirectOnSave && estimate.id && estimate.id.length > 0) {
            navigate(`/estimate/${estimate.id}`);
        }
    }, [estimate.id, isBusy, redirectOnSave, navigate]);

    return (
        <Container>
            <Backdrop className="backdrop" open={isBackdropOpen} style={{zIndex: 9999}}>
                <CircularProgress />
            </Backdrop>

            <Paper elevation={3}>
            <div className="filter-container">
                <div>
                    <EstimateFilter />
                </div>
                <div className="button-container">
                    <Button color="primary" variant="contained" onClick={onNewEstimateClick}>
                        <Icon>add_box</Icon>
                        <span>&nbsp;New Estimate</span>
                    </Button>
                </div>
            </div>
            </Paper>

            <EstimateList
                estimates={estimates}
                pagination={pagination}
                page={page}
                size={size}
                onEstimateClick={onEstimateClick}
                onPaginationChange={onPaginationChange}
            />

            <NewEstimateModal open={isNewEstimateOpen} onCancel={() => setIsNewEstimateOpen(false)} onSave={onSave} />

        </Container>
    );
};
